import React, { useState } from "react";

const CheckboxList = ({ data, selectedItems, setSelectedItems }) => {
  const handleSelectAll = (isChecked) => {
    setSelectedItems(isChecked ? data.map((item) => item.id) : []);
  };

  const handleCheckboxChange = (id, isChecked) => {
    const newSelectedItems = [...selectedItems]; // Create a copy
    if (isChecked) {
      newSelectedItems.push(id);
    } else {
      const index = newSelectedItems.indexOf(id);
      newSelectedItems.splice(index, 1);
    }
    setSelectedItems(newSelectedItems);
  };

  return (
    <div className="flex flex-col space-y-2">
       <h2 className="text-xl font-medium">Select Grades:</h2> 
      <label className="flex items-center space-x-2 py-2 px-4 rounded-md bg-gray-100">
        <input
          type="checkbox"
          checked={selectedItems.length === data.length}
          onChange={(e) => handleSelectAll(e.target.checked)}
          className="focus:ring-primary focus:ring-opacity-50 rounded-sm"
        />
        <span className="text-sm font-medium text-gray-700">Select All</span>
      </label>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
        {data.map((item) => (
          <label
            key={item.id}
            className="flex  items-center gap-x-2 py-2 px-4 mr-2 mb-2 rounded-md border border-gray-200"
          >
            <input
              type="checkbox"
              id={item.id}
              checked={selectedItems.includes(item.id)}
              onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
              className={`focus:ring-primary focus:ring-opacity-50 rounded-sm ${
                selectedItems.includes(item.id) ? "bg-primary text-white" : ""
              }`}
            />
            <span className="text-sm text-gray-700">{item.g_name}</span>
          </label>
        ))}
      </div>
      {/* Optionally display the selected IDs */}
      {/* {selectedItems.length > 0 && (
        <p>Selected IDs: {selectedItems.join(", ")}</p>
      )} */}
    </div>
  );
};

export default CheckboxList;
