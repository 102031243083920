import React from "react";
import Logo from "../GPS-Logo.svg";
import Sidebar from "../components/sideBar";
import { Outlet } from "react-router-dom";
import NavBar from "../components/navBar";

const ProtectedLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  const toggleSidebar = () => {
    console.log(isSidebarOpen);
    setIsSidebarOpen(!isSidebarOpen);
  };
  const hideSideBar = () => {
    console.log("ssss", isSidebarOpen);
    setIsSidebarOpen(false);
  };
  const ToggleBtn = () => {
    return (
      <button
        className="p-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        onClick={toggleSidebar}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
          />
        </svg>
      </button>
    );
  };

  return (
    <div className="w-screen h-screen bg-white flex-row flex justify-start   transition-all duration-600 ease-in">
      <Sidebar isOpen={isSidebarOpen} onHide={hideSideBar} />
      <div className="flex-grow p-4 bg-gray-100 flex flex-row justify-center overflow-y-scroll">
        <div className=" w-full ">
          <NavBar handleToggleSideBar={toggleSidebar}/>
          {/* Your content area here */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProtectedLayout;
