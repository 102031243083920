export const DeviceDesktop = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.75"
          y="3.66663"
          width="16.5"
          height="11"
          rx="1"
          stroke="#2F2B3D"
          stroke-opacity="0.9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.4165 18.3333H15.5832"
          stroke="#2F2B3D"
          stroke-opacity="0.9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.24984 14.6666V18.3333"
          stroke="#2F2B3D"
          stroke-opacity="0.9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.7498 14.6666V18.3333"
          stroke="#2F2B3D"
          stroke-opacity="0.9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
