
import AuthApiEndPoint from './auth'
import gradesApiEndPoint from './grades'
import TypeApiEndPoint from './Type'
import YearsApiEndPoint from './Years'
import subjectsApiEndPoint from './subjects'
import termsApiEndPoint from './Terms'
import qrApiEndPoint from './qr'
import pdfApiEndPoint from './pdf'
import excelApiEndPoint from './excel'
import ZipApiEndPoint from './zipFile'
import UsersApiEndPoint from './users'
import homeApiEndPoint from './home'
import TeachersApiEndPoint from './teachers'


const Api = {
  auth:AuthApiEndPoint,
  grades:gradesApiEndPoint,
  Years:YearsApiEndPoint,
  Type:TypeApiEndPoint,
  Terms:termsApiEndPoint,
  subjects:subjectsApiEndPoint,
  qr:qrApiEndPoint,
  pdf:pdfApiEndPoint,
  excel:excelApiEndPoint,
  zip:ZipApiEndPoint,
  users:UsersApiEndPoint,
  teachers:TeachersApiEndPoint,
  home:homeApiEndPoint
}

export default Api
