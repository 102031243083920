import { useEffect, useState } from "react";
import Api from "../../api/api";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Loading from "../../components/loading";
import Model from "../../components/model";
import toast from "react-hot-toast";

export const ArchivedGrades = (props) => {
  const [gradesData, setGradesData] = useState([]);

  const [deletedGrade, setDeletedGrade] = useState({});
  const [onChange, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [restoreLoading, setRestoreLoading] = useState(false);
  const authHeader = useAuthHeader();
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [restoredgrade, setRestoredGrade] = useState({});
  const [isOpenRestorModel, setIsOpenRestorModel] = useState(false);

  const handleOpenDeleteModel = () => {
    setIsOpenDeleteModel(true);
  };

  const handleCloseDeleteModel = () => {
    setIsOpenDeleteModel(false);
  };
  const handleOpenRestoredModel = () => {
    setIsOpenRestorModel(true);
  };

  const handleCloseRestoreModel = () => {
    setIsOpenRestorModel(false);
  };

  useEffect(() => {
    // console.log(authHeader)
    setLoading(true);
    Api.grades
      .getAllArchivedGrades(authHeader)
      .then((res) => {
        setGradesData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onChange]);

  const handleDeleteGrade = () => {
    setDeleteLoading(true);
    Api.grades
      .hardDeleteGrade({ grade_id: deletedGrade?.id }, authHeader)
      .then((res) => {
        // console.log(res);
        if (res.status) {
          handleCloseDeleteModel();
          setDeletedGrade({});
          setDeleteLoading(false);
          setChange(!onChange);
          toast.success("Deleted successfully");
        } else {
          toast.error("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        setDeleteLoading(false);
        toast.error("An error occurred. Please try again");
      });
  };
  const handleRestoreGrade = () => {
    document.title="QR system - Archived Grades"

    setRestoreLoading(true);
    Api.grades
      .restoreGrade({ grade_id: restoredgrade?.id }, authHeader)
      .then((res) => {
        // console.log(res);
        if (res.status) {
          handleCloseRestoreModel();
          setRestoredGrade({});
          setRestoreLoading(false);
          setChange(!onChange);
          toast.success("Restored successfully");
        } else {
          toast.error("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        setRestoreLoading(false);
        toast.error("An error occurred. Please try again");
      });
  };
  if (loading) return <Loading />;
  return (
    <div className="w-100 flex flex-col gap-5">
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            Archived Grades
          </p>
        </div>
      </div>

      <div className="w-100  px-5">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden -6">
          <thead className="min-w-full bg-gray-200 text-gray-700 ">
            <tr className="min-w-full flex flex-row">
              <th className=" flex-none max-w-fit min-w-[80px]  px-4 py-2 text-left">
                #
              </th>
              <th className="grow px-4 py-2  text-left">Grade Name</th>
              <th className=" flex-none max-w-fit min-w-[100px] px-4 py-2 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="min-w-full text-gray-600">
            {gradesData?.length === 0 ? (
              <tr className="min-w-full flex flex-row">
                <th></th>
                <th className="grow px-4   py-2">No data found</th>
                <th></th>
              </tr>
            ) : null}

            {gradesData?.map((grade, index) => (
              <tr
                key={grade.id}
                className="min-w-full flex flex-row transition-all hover:bg-gray-100 border-b border-gray-200"
              >
                <td className="flex-none max-w-fit min-w-[80px] px-4   py-2">
                  {index + 1}
                </td>
                <td className="grow px-4   py-2">{grade.g_name}</td>
                <td className="flex-none max-w-fit min-w-[100px]">
                  <button
                    className=" hover:text-primary focus:outline-none rounded-full p-2"
                    onClick={() => {
                      setRestoredGrade(grade);
                      handleOpenRestoredModel();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                      />
                    </svg>
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700 focus:outline-none rounded-full p-2"
                    onClick={() => {
                      setDeletedGrade(grade);
                      handleOpenDeleteModel();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Model
        isOpen={isOpenDeleteModel}
        onClose={handleCloseDeleteModel}
        title="Delete Grade"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-red-600 text-2xl mx-auto">
            {deletedGrade.g_name}
          </h3>
          <div className="flex flex-row gap-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-red-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
            <p className="text-gray-600">
              It will be delete this grade permanently{" "}
            </p>
          </div>
          <button
            onClick={handleDeleteGrade}
            className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-red-700 hover:bg-opacity-95 text-white"
          >
            {deleteLoading ? (
              <Loading size="sm" color="text-white" />
            ) : (
              "Delete Grade"
            )}
          </button>
        </div>
      </Model>
      <Model
        isOpen={isOpenRestorModel}
        onClose={handleCloseRestoreModel}
        title="Restore Grade"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-primary text-2xl mx-auto">
            {restoredgrade.g_name}
          </h3>
          <div className="flex flex-row gap-x-3">
            <p className="text-gray-600">It will be restore this grade</p>
          </div>
          <button
            onClick={handleRestoreGrade}
            className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
          >
            {restoreLoading ? (
              <Loading size="sm" color="text-white" />
            ) : (
              "Restore Grade"
            )}
          </button>
        </div>
      </Model>
    </div>
  );
};
