import { ApiHandler } from './index'

const api = new ApiHandler()

const homeApiEndPoint = {
  getHomeInfo: async (token) => {
    try {
      const res = await api.get('/gethome/info',token)
       return res
    } catch (error) {
      console.error('Error fetching grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
}

export default homeApiEndPoint
