import React, { useEffect, useState } from "react";
import Api from "../../api/api";
import Loading from "../../components/loading";
import Customize from "./Customize";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const DeleteModel = ({ itemName, itemId, Refresh }) => {
  console.log(itemName);
  console.log("itemId is :- ", itemId);
  const [Load, setLoad] = useState(false);
  const [isOpenEditModel, setIsOpenEditModel] = useState(false);
  const [Open, setOpen] = useState(false);
  const handelOpen = () => {
    console.log("handelOpen");
    setOpen(true);
  };
  const handelClose = () => {
    console.log("handelClose");
    setOpen(false);
  };
  const authHeader = useAuthHeader();

  const handelDelete = () => {
    setLoad(true);
    const data = { term_id: itemId };
    Api[Customize.ApiName]
      .softDelete(authHeader, data)
      .then((res) => {
        if (res.status) {
          Refresh();
        }
        setLoad(false);
        setIsOpenEditModel(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  return (
    <div className="relative  inline-block text-left flex flex-row gap-4 ">
      <>
        <button
          onClick={handelOpen}
          className="text-red-500 hover:text-red-700 focus:outline-none rounded-full p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
        <div
          className={`fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex justify-center items-center transition-opacity duration-300 ease-in-out ${
            Open
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="relative bg-white rounded-lg shadow-md p-4 overflow-auto max-h-[80vh] min-w-[350px]">
            <h3 className="mb-3 text-xl font-medium">
              Delete {Customize.name}
            </h3>
            <div className="flex justify-center items-center flex-col gap-3">
              <h3 className="text-red-600">{itemName}</h3>
              <button
                onClick={handelDelete}
                className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-red-700 hover:bg-opacity-95 text-white"
              >
                {Load ? <Loading size="sm" color={"white"} /> : "Delete Term"}
              </button>
            </div>
            {true && (
              <button
                className="absolute top-0 right-0 mt-4 px-4 py-2 rounded-md text-sm font-medium"
                // onClick={onClose}
              >
                <svg
                  onClick={handelClose}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </>

      {/* Model  --------------------------------------------------------*/}
    </div>
  );
};

export default DeleteModel;
