import { ApiHandler } from './index'

const api = new ApiHandler()

const gradesApiEndPoint = {
  getAllGrades: async (token) => {
    try {
      const res = await api.get('/grade/view',token)
      return res
    } catch (error) {
      console.error('Error fetching grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  getAllArchivedGrades: async (token) => {
    try {
      const res = await api.get('/grade/viewarchived',token)
      return res
    } catch (error) {
      console.error('Error fetching grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  addGrade: async (data,token) => {
    try {
      const res = await api.post('/grade/add',data,token)
      return res
    } catch (error) {
      console.error('Error adding grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  updateGrade: async (data,token) => {
    try {
      const res = await api.post('/grade/update',data,token)
      return res
    } catch (error) {
      console.error('Error Updating grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  restoreGrade: async (data,token) => {
    try {
      const res = await api.post('/grade/restore_data',data,token)
      return res
    } catch (error) {
      console.error('Error Updating grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  softDeleteGrade: async (data,token) => {
    try {
      const res = await api.post('/grade/soft_delete',data,token)
      return res
    } catch (error) {
      console.error('Error fetching grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  hardDeleteGrade: async (data,token) => {
    try {
      const res = await api.post('/grade/hard_delete',data,token)
      return res
    } catch (error) {
      console.error('Error fetching grades:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
}

export default gradesApiEndPoint
