import React, { useState } from "react";

export default function Pagination({
  current_page,
  last_page,
  callback,
  set_per_page,
}) {
  const [value, setValue] = useState(current_page);
  const [perPage, setPerPage] = useState(20);

  const handlePageClick = (page) => {
    if (page !== current_page) {
      callback(page);
    }
  };

  const handlePerPageChange = (e) => {
    const newPerPage = Number(e.target.value);
    setPerPage(newPerPage);
    set_per_page(newPerPage);
  };

  const handleGoToPage = () => {
    const page = Number(value);
    if (page >= 1 && page <= last_page) {
      callback(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(2, current_page - 2);
    const endPage = Math.min(last_page - 1, current_page + 2);

    // Always show the first page
    pageNumbers.push(
      <span
        key={1}
        onClick={() => handlePageClick(1)}
        className={`px-2 sm:px-4 py-2 border rounded-md cursor-pointer ${
          current_page === 1
            ? "bg-gray-500 text-white"
            : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100 hover:text-black"
        }`}
      >
        1
      </span>
    );

    // Show pages around the current page
    if (startPage > 2) {
      pageNumbers.push(
        <span key="start-ellipsis" className="px-2 py-2 text-gray-700">
          ...
        </span>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => handlePageClick(i)}
          className={`px-2 sm:px-4 py-2 border rounded-md cursor-pointer ${
            i === current_page
              ? "bg-gray-500 text-white"
              : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100 hover:text-black"
          }`}
        >
          {i}
        </span>
      );
    }

    // Show ellipsis if there's a gap between last range and last page
    if (endPage < last_page - 1) {
      pageNumbers.push(
        <span key="end-ellipsis" className="px-2 py-2 text-gray-700">
          ...
        </span>
      );
    }

    // Always show the last page
    if (last_page > 1) {
      pageNumbers.push(
        <span
          key={last_page}
          onClick={() => handlePageClick(last_page)}
          className={`px-2 sm:px-4 py-2 border rounded-md cursor-pointer ${
            current_page === last_page
              ? "bg-gray-500 text-white"
              : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100 hover:text-black"
          }`}
        >
          {last_page}
        </span>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="m-3 flex flex-col sm:flex-row gap-3 justify-center items-center py-5">
      <div className="flex flex-col md:flex-row gap-2 overflow-x-auto">
        <button
          onClick={() => handlePageClick(current_page - 1)}
          disabled={current_page === 1}
          className={`px-2 sm:px-4 w-fit mx-auto py-2 border rounded-md cursor-pointer ${
            current_page === 1
              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
              : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100 hover:text-black"
          }`}
        >
          Previous
        </button>

        <div className="flex gap-1 sm:gap-2">{renderPageNumbers()}</div>

        <button
          onClick={() => handlePageClick(current_page + 1)}
          disabled={current_page === last_page}
          className={`px-2 sm:px-4 w-fit mx-auto py-2 border rounded-md cursor-pointer ${
            current_page === last_page
              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
              : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100 hover:text-black"
          }`}
        >
          Next
        </button>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="w-16 sm:w-20 px-2 sm:px-4 py-2 text-gray-700 bg-white border rounded-md border-gray-300"
          min={1}
          max={last_page}
          placeholder="Page"
        />
        <button
          onClick={handleGoToPage}
          className="px-2 sm:px-4 py-2 text-gray-700 bg-white border rounded-md border-gray-300"
        >
          Go
        </button>
      </div>
    </div>
  );
}
