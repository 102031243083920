import React, { useState } from "react";
import { Link } from "react-router-dom";

const CollapsibleNavbarLink = ({ icon, label, path, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="flex flex-col divide-y px-4 py-3 text-gray-500 hover:text-primary hover:bg-gray-100 hover:rounded-lg">
      <button type="button" onClick={toggleDropdown}>
        <div className="flex flex-row gap-2 justify-between text-lg font-medium">
          <div className="flex flex-row gap-2">
            {icon}
            {label}
          </div>

          {isOpen ? (
            <button className="h-6 w-6 text-gray-500 transition-transform duration-300 ease-in-out transform rotate-90">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          ) : (
            <button className="h-6 w-6 text-gray-500 transition-transform duration-300 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          )}
        </div>
      </button>
      
      {isOpen && (
        <ul className="pl-4 text-sm text-gray-700">
          {children.map((child) => (
            <li key={child.key || child.label}>{child}</li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default CollapsibleNavbarLink;
