import "./App.css";
import React from "react";
import AuthProvider from "react-auth-kit";
import RoutesComponent from "./Routes";
import createStore from "react-auth-kit/createStore";
import { Toaster } from "react-hot-toast";

const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});

function App() {
  return (
    <AuthProvider store={store}>
      <Toaster />
      <RoutesComponent />
    </AuthProvider>
  );
}

export default App;
