import * as React from "react";
import { ApiHandler } from "../api";
import Swal from "sweetalert2";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useNavigate, Navigate } from "react-router-dom";
import Logo from "../GPS-Logo.svg";
import Api from "../api/api";
import toast from "react-hot-toast";

export const Login = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const isAuthenticated = useIsAuthenticated();
  const signIn = useSignIn();
  const navigate = useNavigate();

  React.useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (email.length == 0 && password.length == 0) {
      setErrors({
        email: "Email required",
        password: "Password required",
      });
    } else if (email.length == 0 && password.length > 0) {
      setErrors({
        email: "Email required",
        password: "",
      });
    } else if (email.length > 0 && password.length == 0) {
      setErrors({
        email: "",
        password: "Password required",
      });
    } else {
      setErrors({
        email: "",
        password: "",
      });
      Api.auth
        .login({ email: email, password: password })
        .then((res) => {
          if (res.status) {
            // console.log(res)
            signIn({
              auth: {
                token: res.token,
              },
              userState: { uid: res.user.id, ...res.user },
            });

            navigate("/");
          } else {
            if (res.message) {
              setError(res.message);
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          toast.error("An error occurred. Please try again");
        });
    }
  };
  if (isAuthenticated) {
    return <Navigate to={"/"} replace />;
  } else {
    return (
      <div className="w-screen h-screen bg-gray-100 flex-col flex gap-10 justify-center items-center  transition-all duration-600 ease-in ">
        <img src={Logo} alt="Logo" className="max-w-[250px] pb-10" />
        <div className=" w-full sm:w-[400px] flex flex-col justify-center items-center gap-3 mb-3 bg-transparent shadow-none  rounded-lg p-5 md:bg-white md:shadow-lg">
          <div className="w-full flex flex-col">
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`flex-grow-0  rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50 ${
                errors.email ? "border-red-500" : ""
              }`}
              placeholder="Enter your Email"
              required
            />
            {errors.email && ( // Display error message conditionally
              <div className="w-fill text-red-500 text-xs flex flex-row justify-start">
                {errors.email}
              </div>
            )}
          </div>

          <div className="w-full flex flex-col">
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`flex-grow-0  rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50 ${
                errors.password ? "border-red-500" : ""
              }`}
              placeholder="Enter your password"
              required
            />
            {errors.password && ( // Display error message conditionally
              <div className="w-fill text-red-500 text-xs flex flex-row justify-start">
                {errors.password}
              </div>
            )}
          </div>
          {error && ( // Display error message conditionally
            <div className="w-fill text-red-500 text-sm flex flex-row justify-start">
              {error}
            </div>
          )}
          <button
            className="w-fit px-7 py-2 rounded-md text-md font-medium bg-blue-500 hover:bg-blue-700 text-white"
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
};
