import React, { useEffect, useState } from "react";
import Api from "../../api/api.js";
import Customize from "./Customize.js";
import Loading from "../../components/loading.js";
import HardDeleteModel from "./HardDeleteModel.js";
import Addscomponent from "./Addscomponent.js";
import EditModel from "./RestoreModel.js";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
export const YearsArchives = (props) => {
  const [Load, setLoad] = useState(false);
  const [Data, setData] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const authHeader = useAuthHeader();

  const fetchingData = () => {
    setLoad(true);
    Api[Customize.ApiName]
      .archived(authHeader)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  useEffect(() => {
    document.title="QR system - Archived Years"

    fetchingData();
  }, [refresh]);

  if (Load) return <Loading />;

  return (
    <div>
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            {Customize.name}
          </p>
        </div>
      </div>

      <div className="overflow-x-auto m-auto p-5 ">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden -6">
          <thead className="min-w-full bg-gray-200 text-gray-700 ">
            <tr className="min-w-full flex flex-row">
              <th className=" flex-none max-w-fit min-w-[80px]  px-4 py-2 text-left">
                #
              </th>
              <th className="grow px-4 py-2  text-left">Year Name</th>
              <th className=" flex-none max-w-fit min-w-[100px] px-4 py-2 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {Data?.length === 0 ? (
              <tr>
                <td className="text-center">No data  found</td>
              </tr>
            ) : null}
            {Load ? (
              <tr>
                <td className="text-center">
                  <Loading />
                </td>
              </tr>
            ) : (
              <>
                {Data &&
                  Data?.map((item, index) => (
                    <tr
                      key={item.id}
                      className="min-w-full flex flex-row transition-all hover:bg-gray-100 border-b border-gray-200"
                    >
                      <td className="flex-none max-w-fit min-w-[80px] px-4   py-2">
                        {index + 1}
                      </td>
                      <td className="grow px-4   py-2">{item.y_name}</td>
                      <td className="flex-none max-w-fit min-w-[100px]">
                        <div className="flex flex-row ">
                          <EditModel
                            Refresh={() => setRefresh(!refresh)}
                            itemName={item.y_name}
                            itemId={item.id}
                          />
                          <HardDeleteModel
                            Refresh={() => setRefresh(!refresh)}
                            itemName={item.y_name}
                            itemId={item.id}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
