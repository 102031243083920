export const ViewsIcon = () => {
  return (
    <div className="w-[22px] h-[22px]">
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <circle
          cx="13.9998"
          cy="14"
          r="2.33333"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.0002 22.1667C9.3335 22.1667 5.445 19.4448 2.3335 14C5.445 8.55518 9.3335 5.83334 14.0002 5.83334C18.6668 5.83334 22.5553 8.55518 25.6668 14C25.1768 14.8587 24.6658 15.6497 24.1373 16.3718"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.5 22.1667L19.8333 24.5L24.5 19.8333"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
