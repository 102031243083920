import { ApiHandler } from "./index";

const api = new ApiHandler();

const AuthApiEndPoint = {
  login: async (data) => {
    try {
      const res = await api.post("/login", data);

      return res;
    } catch (error) {
      console.error("Error login:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  logout: async (token) => {
    try {
      const res = await api.post("/user/logout", {}, token);

      return res;
    } catch (error) {
      console.error("Error login:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  getProfile: async (token) => {
    try {
      const res = await api.get("/user/getoneuser", token);

      return res;
    } catch (error) {
      console.error("Error login:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  UpdateProfile: async (data, token) => {
    try {
      const res = await api.post("/user/update_profile", data, token);

      return res;
    } catch (error) {
      console.error("Error login:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
};

export default AuthApiEndPoint;
