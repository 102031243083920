import { Link, useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import Api from "../../api/api";
import toast from "react-hot-toast";
import Model from "../../components/model";

export const UpdateProfile = (props) => {
  const navigate = useNavigate();
  const useHeader = useAuthHeader();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const authUser = useAuthUser();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (event.target.name === "email" && !emailRegex.test(event.target.value)) {
      setErrors({ ...errors, email: "Please enter a valid email address." });
    } else if (
      event.target.name === "email" &&
      emailRegex.test(event.target.value)
    ) {
      setErrors({ ...errors, email: "" });
    }

    if (
      event.target.name === "password" &&
      event.target.value.length <= 5 &&
      event.target.value.length !== 0
    ) {
      setErrors({
        ...errors,
        password: "Password must be at least 6 characters ",
      });
    } else if (
      event.target.name === "password" &&
      (event.target.value.length > 5 || event.target.value.length == 0)
    ) {
      setErrors({ ...errors, password: "" });
    }

    if (event.target.name === "name" && event.target.value.length == 0) {
      setErrors({
        ...errors,
        name: "Name must required",
      });
    } else if (event.target.name === "name" && event.target.value.length > 0) {
      setErrors({ ...errors, name: "" });
    }
  };

  useEffect(() => {
    Api.auth
      .getProfile(useHeader)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setFormData({
            ...formData,
            name: res.data.name,
            email: res.data.email,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  function updateAuthStateCookie(userData, newName, newEmail) {
    const newUserData = { ...userData, name: newName, email: newEmail }; // Update properties
    const newCookieValue = JSON.stringify(newUserData);

    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000); // One day in milliseconds

    const newCookie = `_auth_state=${newCookieValue}; expires=${expires.toUTCString()}; path=/`; // Adjust path if needed

    document.cookie = newCookie;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    Api.auth
      .UpdateProfile(formData, useHeader)
      .then((res) => {
        if (res.status) {
          updateAuthStateCookie(authUser, formData.name, formData.email);
          toast.success("Updated Successfully");
          setTimeout(() => {
            navigate("/");
          }, 1500);
        }
      })
      .catch();
  };
  if (loading) return <Loading />;

  return (
    <div className="w-100 flex flex-col gap-5">
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            Update Profile
          </p>
        </div>
      </div>

      <form className="w-100" onSubmit={handleSubmit}>
        <div className="w-100 grid grid-cols-1 sm:grid-cols-2 pt-10 gap-5 space-x-2 bg-white p-2 rounded-lg">
          <div className="flex flex-col gap-1">
            <label htmlFor="name" className=" text-sm font-medium">
              Name:
            </label>
            <input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`flex-grow-0  rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50 ${
                errors.name ? "border-red-500" : ""
              }`}
              type="text" // Set input type to "text" for a text field
            />
            {errors.name && ( // Display error message conditionally
              <div className="text-red-500 text-xs">{errors.name}</div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-sm font-medium">
              Email:
            </label>
            <input
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`flex-grow-0  rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50 ${
                errors.email ? "border-red-500" : ""
              }`}
              type="text"
            />
            {errors.email && ( // Display error message conditionally
              <div className="text-red-500 text-xs">{errors.email}</div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className=" text-sm font-medium">
              New Password:
            </label>
            <input
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={`flex-grow-0  rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50 ${
                errors.password ? "border-red-500" : ""
              }`}
              type="password" // Set input type to "text" for a text field
            />
            {errors.password && ( // Display error message conditionally
              <div className="text-red-500 text-xs">{errors.password}</div>
            )}
          </div>
          {/* Add additional input fields as needed */}
          <div className="w-100 flex flex-row gap-3 col-span-2">
            <button
              type="submit"
              disabled={errors.email || errors.password || errors.name}
              className="w-fit mx-auto py-2 px-6 bg-primary text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2"
            >
              Update Profile
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
