export const DeviceMobile = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="6.4165"
          y="3.66663"
          width="9.16667"
          height="14.6667"
          rx="1"
          stroke="#2F2B3D"
          stroke-opacity="0.9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.0835 4.58333H11.9168"
          stroke="#2F2B3D"
          stroke-opacity="0.7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.9998 15.5834V15.5926"
          stroke="#2F2B3D"
          stroke-opacity="0.7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
