import { Link, Navigate, useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import Api from "../../api/api";
import toast from "react-hot-toast";
import Select from "react-select";

export const Bulk = (props) => {
  const navigate = useNavigate();
  const useHeader = useAuthHeader();
  const [loading, setLoading] = useState(false);
  const [ZIPfile, setZIPfile] = useState(null);
  const [gradesData, setGradesData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [formData, setFormData] = useState({
    grade_ids: [],
    subject_id: "",
    year: "",
    term_id: "",
    link: "",
    tree: "",
    title: "",
    number: "",
  });

  const handleChange = (event) => {
    if (event.target.name === "grade_ids") {
      Api.subjects
        .getSubjectByGradeId(event.target.value, useHeader)
        .then((res) => {
          if (res.status) {
            setSubjectsData(res?.data?.subjects);
          }
        })
        .catch((err) => {});
    }
    if (event.target.name === "grade_ids" && event.target.value === "") {
      setSubjectsData([]);
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeGrades = (grades) => {
    setFormData({
      ...formData,
      grade_ids: grades,
    });
    Api.subjects
      .getSubjectByGradeId(useHeader, {
        grade_ids: grades?.map((g) => {
          return g.id;
        }),
      })
      .then((res) => {
        if (res.status) {
          setSubjectsData(res?.data);
        }
      })
      .catch((err) => {});
  };

  // useHeader
  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFormData = {
      ...formData,
      grade_ids: JSON.stringify(
        formData?.grade_ids?.map((g) => {
          return g.id;
        })
      ),
    };
    let data = {
      number: "5",
      grade_ids: "2",
      subject_id: "2",
      term_id: "1",
      link: "https://lumen.laravel.com/docs/10.x/routing",
      year: "2025",
      title: "تجريبي7",
    };

    Api.qr
      .bulk({ ...newFormData }, useHeader)
      .then((res) => {
        console.log(res["Zip File Link"]);

        // const obj = res.data;
        // for (let key in obj) {
        //   if (key.toLowerCase().includes("zip")) {
        //     console.log(obj[key]);
        //     setZIPfile(obj[key]);
        //   }
        // }

        if (res.status) {
          toast.success("Added successfully");
          setZIPfile(res["Zip File Link"]);
          window.open(res["Zip File Link"], "_blank");
          navigate("/QR");
        } else toast.error("An error occurred. Please try again");
      })
      .catch((err) => {
        toast.error("An error occurred. Please try again");
      });
  };

  useEffect(() => {
    document.title = "QR system - Bulk";

    setLoading(true);
    Api.grades
      .getAllGrades(useHeader)
      .then((res1) => {
        if (res1.status) {
          setGradesData(res1.data);
        }
        return Api.Terms.getAll(useHeader);
      })
      .then((res3) => {
        if (res3.status) {
          setTermsData(res3.data);
        }
        return Api.Years.getAll(useHeader);
      })
      .then((res4) => {
        if (res4.status) {
          setYearsData(res4.data);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (loading) return <Loading />;

  return (
    <div className="w-100 flex flex-col gap-5">
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            Create New QR bulk
          </p>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="w-100 grid grid-cols-1 sm:grid-cols-2 pt-12 gap-10  bg-white p-2 rounded-lg"
      >
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="grade_ids" className=" text-sm font-medium">
            Grade:<span className="text-red-600">*</span>
          </label>
          <Select
            isMulti // Enable multi-select
            id="grade_ids"
            name="grade_ids" // Name for the generated array
            defaultValue={formData.grade_ids} // Initial selection
            value={formData.grade_ids} // Controlled value
            className="flex-grow"
            placeholder="-- Select Grade --"
            onChange={
              handleChangeGrades
              //   (newValue) => {
              //   setFormData({
              //     ...formData,
              //     grade_ids: newValue,
              //   });
              //   // Update the multi-select value
              //   // setValue("SubjectDivisions", newValue);
              // }
            }
            options={gradesData}
            isSearchable
            isClearable
            getOptionLabel={(option) => option.g_name}
            getOptionValue={(option) => option.id}
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="subject_id" className=" text-sm font-medium">
            Subject:<span className="text-red-600">*</span>
          </label>
          <select
            id="subject_id"
            name="subject_id"
            value={formData.subject_id}
            onChange={handleChange}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
          >
            <option value="">-- Select Subject --</option>
            {subjectsData.map((subject) => (
              <option key={subject.id} value={subject.id}>
                {subject.s_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="term_id" className=" text-sm font-medium">
            Term:<span className="text-red-600">*</span>
          </label>
          <select
            id="term_id"
            name="term_id"
            value={formData.term_id}
            onChange={handleChange}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
          >
            <option value="">-- Select Term --</option>
            {termsData.map((term) => (
              <option key={term.id} value={term.id}>
                {term.t_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="year" className=" text-sm font-medium">
            Year:<span className="text-red-600">*</span>
          </label>
          <select
            id="year"
            name="year"
            value={formData.year}
            onChange={handleChange}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
          >
            <option value="">-- Select Year --</option>
            {yearsData.map((year) => (
              <option key={year.id} value={year.y_name}>
                {year.y_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="link" className=" text-sm font-medium">
            Link:<span className="text-red-600">*</span>
          </label>
          <input
            id="link"
            name="link"
            value={formData.link}
            onChange={handleChange}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            type="text" // Set input type to "text" for a text field
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="tree" className=" text-sm font-medium">
            Note:<span className="text-red-600">*</span>
          </label>
          <input
            id="tree"
            name="tree"
            value={formData.tree}
            onChange={handleChange}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            type="text" // Set input type to "text" for a text field
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="title" className=" text-sm font-medium">
            Title:<span className="text-red-600">*</span>
          </label>
          <input
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            type="text" // Set input type to "text" for a text field
          />
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
          <label htmlFor="number" className=" text-sm font-medium">
            Number Of Qr:<span className="text-red-600">*</span>
          </label>
          <input
            id="number"
            name="number"
            required
            type="number"
            value={formData.number}
            onChange={handleChange}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
          />
        </div>

        {/* {ZIPfile && (
          <a  target="_blank" className="w-fit  mx-auto py-2 px-6 flex flex-row gap-3 bg-gray-800 animate-bounce  text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2" href={ZIPfile}>
           
              Download
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
           </a>
        )} */}

        <button
          type="submit"
          className="w-fit col-span-2 mx-auto py-2 px-6 bg-primary text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2"
        >
          Create New bulk
        </button>

        {/* Add additional input fields as needed */}
      </form>
    </div>
  );
};
