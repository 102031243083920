import React, { useState } from "react";

const ModelBtn = ({ children, btn, title, isOpen = false, onClose }) => {
  const [Open, setOpen] = useState(false);
  const handelOpen = () => {
    console.log("handelOpen");
    setOpen(true);
  };
  const handelClose = () => {
    console.log("handelClose");
    setOpen(false);
  };

  return (
    <>
      <span onClick={handelOpen}>{btn}</span>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex justify-center items-center transition-opacity duration-300 ease-in-out ${
          isOpen && Open
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="relative bg-white rounded-lg shadow-md p-4 overflow-auto max-h-[80vh] min-w-[350px]">
          {title && <h3 className="mb-3 text-xl font-medium">{title}</h3>}
          {children}
          {onClose && (
            <button
              className="absolute top-0 right-0 mt-4 px-4 py-2 rounded-md text-sm font-medium"
              onClick={onClose}
            >
              <svg
                onClick={handelClose}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ModelBtn;
