// import toast from "react-hot-toast";
import { ApiHandler } from "./index";
import axios from "axios";

const api = new ApiHandler();

const excelApiEndPoint = {
  generateExcel: async (data, token) => {
    try {
      const res = await api.post("/generate/excel",data, token );
      return res;
    } catch (error) {
      console.error("Error generate pdf:", error);
    }
  },
};

export default excelApiEndPoint;
