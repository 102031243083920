import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Api from "../../api/api";
import Loading from "../../components/loading";
import toast from "react-hot-toast";
import { ViewsIcon } from "../../assets/icons/views";
import { VisitorsIcon } from "../../assets/icons/visitors";
import { DeviceDesktop } from "../../assets/icons/deviceDesktop";
import { DeviceMobile } from "../../assets/icons/deviceMobile";
import { DeviceTablet } from "../../assets/icons/deviceTablet";
import { RobotIcon } from "../../assets/icons/robotIcon";

export const QrInfo = (props) => {
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const useHeader = useAuthHeader();
  const { qr_code } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [qrData, setQrData] = React.useState(null);
  const [profileList, setProfileList] = React.useState(false);

  const handleGetOneQR = async (event) => {
    Api.qr
      .GetOneQR(useHeader, qr_code)
      .then((res) => {
        if (res.status) {
          setQrData({ ...res.data });
          setLoading(false);
        } else toast.error("An error occurred. Please try again");
      })
      .catch((err) => {
        toast.error("An error occurred. Please try again");
      });
  };

  React.useEffect(() => {
    document.title = `QR system - Statistics - ${qr_code}`;
    handleGetOneQR();
  }, []);

  function RowInfo({ title, icon, subTitle, content }) {
    return (
      <div className="  flex flex-col gap-2 w-full border bg-white rounded-lg py-10 px-5">
        <div className="flex flex-row w-full items-center gap-4">
          <div className="bg-gray-200 p-1 rounded-md">{icon}</div>
          <div className="text-3xl font-medium">{title}</div>
        </div>
        <div className="text-stone-600  ">{subTitle}</div>
        <div className="  ">{content}</div>
      </div>
    );
  }
  function DetailsRowInfo({ title, subTitle, content }) {
    return (
      <div className="  flex flex-col gap-3 w-full border bg-white rounded-lg py-5 px-5">
        <div className="flex flex-col gap-1 py-5">
          <div className="text-xl font-medium">{title}</div>
          <div className="text-stone-400  ">{subTitle}</div>
        </div>
        <div className="flex flex-col gap-2 divide-y-2">{content}</div>
      </div>
    );
  }
  function DetailsRowItem({ title, icon, count, content }) {
    return (
      <div className=" flex flex-row justify-between gap-3 w-full py-2">
        <div className="flex flex-row gap-x-4 items-center">
          <div className="bg-gray-200 p-2 rounded-md">{icon}</div>
          <div className="text-lg font-medium">{title}</div>
        </div>
        <div className="min-w-[50%] flex flex-row gap-x-2">
          <div className="text-stone-700 text-lg font-bold">{count}</div>
          <div>{content}</div>
        </div>
      </div>
    );
  }

  if (loading) return <Loading />;
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 pt-5">
      <div className="col-span-1 md:col-span-2 flex flex-col gap-2 px-5">
        <p className=" w-fit font-bold text-xl  border-primary uppercase tracking-wider">
          QR Info
        </p>
        <p className=" text-lg font-semibold text-stone-700">{qrData?.title}</p>
        <p className=" text-lg  text-stone-500">
          {new Date(qrData?.created_at).toLocaleDateString()}
        </p>
      </div>
      <RowInfo
        icon={<ViewsIcon />}
        title={qrData.count}
        subTitle={"Total Views"}
        content={<></>}
      />
      {/*
      <RowInfo
        icon={<VisitorsIcon />}
        title="28.4 k"
        subTitle={"Total Visitors"}
        content={
          <div className="flex flex-row gap-x-2">
            <div>-1.2%</div>
            <div className="text-stone-400">than last week</div>
          </div>
        }
      /> */}
      <div className="col-span-2 md:col-span-3">
        <DetailsRowInfo
          title={
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-x-7 items-center">
                <p className=" text-lg font-semibold text-stone-700">
                  {qrData?.title}
                </p>
                <p className=" text-base  text-stone-500">
                  {new Date(qrData?.created_at).toLocaleDateString()}
                </p>
              </div>
              <p className=" text-base font-semibold text-stone-500">
                {`Notes: ${qrData?.tree}`}
              </p>
            </div>
          }
          subTitle={
            <p className=" text-lg text-stone-700">
              Views by Device
            </p>
          }
          content={
            <>
              <DetailsRowItem
                icon={<DeviceDesktop />}
                title="Desktop"
                count={qrData.desktop_count || "-"}
              />
              <DetailsRowItem
                icon={<DeviceMobile />}
                title="Mobile"
                count={qrData.phone_count || "-"}
              />
              <DetailsRowItem
                icon={<DeviceTablet />}
                title="Tablet"
                count={qrData.tablet_count || "-"}
              />
              <DetailsRowItem
                icon={<RobotIcon />}
                title="Robot"
                count={qrData.robot_count || "-"}
              />
              <DetailsRowItem
                icon={<ViewsIcon />}
                title="Total Count"
                count={qrData.count || "-"}
              />
            </>
          }
        />
      </div>
    </div>
  );
};
