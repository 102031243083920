import React, { useEffect, useState } from "react";
import Model from "../../components/model";
import Api from "../../api/api";
import Loading from "../../components/loading";
import ModelBtn from "../../components/modelBtn";
import Customize from "./Customize";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const RestoreModel = ({ itemName, itemId, Refresh }) => {
  console.log(itemName);
  console.log("itemId is :- ", itemId);
  const [Load, setLoad] = useState(false);
  const [isOpenDeleteModel, setisOpenDeleteModel] = useState(false);
  const [isOpenEditModel, setIsOpenEditModel] = useState(false);
  const [input, setInput] = useState("");
  const [Open, setOpen] = useState(false);
  const handelOpen = () => {
    console.log("handelOpen");
    setOpen(true);
  };
  const handelClose = () => {
    console.log("handelClose");
    setOpen(false);
  };

  const authHeader = useAuthHeader();

  const handelEditYear = () => {
    setLoad(true);
    const data = { term_id: itemId };

    Api[Customize.ApiName]
      .restore(authHeader, data)
      .then((res) => {
        console.log(res);
        if (res.status) {
          Refresh();
        }
        setLoad(false);
        setIsOpenEditModel(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const handelRestoreYear = () => {
    console.log("handelRestoreYear");
  };

  return (
    <div className="relative  inline-block text-left flex flex-row gap-4 ">
      {/* Model  --------------------------------------------------------*/}

      <button
        onClick={handelOpen}
        className=" hover:text-primary focus:outline-none rounded-full p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
          />
        </svg>
      </button>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex justify-center items-center transition-opacity duration-300 ease-in-out ${
          Open
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="relative bg-white rounded-lg shadow-md p-4 overflow-auto max-h-[80vh] min-w-[350px]">
          <h3 className="mb-3 text-xl font-medium">Restore Term</h3>

          <div className="flex justify-center  flex-col gap-3">
            <h3 className="text-primary text-2xl mx-auto">{itemName}</h3>
            <div className="flex flex-row">
              <p className="text-gray-600">It will be restore this Term</p>
            </div>
            <button
              onClick={handelEditYear}
              className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
            >
              {Load ? <Loading size="sm" color={"white"} /> : "Restore Term"}
            </button>
          </div>

          {true && (
            <button
              className="absolute top-0 right-0 mt-4 px-4 py-2 rounded-md text-sm font-medium"
              // onClick={onClose}
            >
              <svg
                onClick={handelClose}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestoreModel;
