import { useEffect, useState } from "react";
import Api from "../../api/api";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Loading from "../../components/loading";
import Model from "../../components/model";
import toast from "react-hot-toast";
import CheckboxList from "./checkBoxList";

export const Subjects = (props) => {
  const [subjectsData, setSubjectsData] = useState([]);
  const [gradesData, setGradesData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newSubjectValue, setNewSubjectValue] = useState("");
  const [editSubjectValue, setEditSubjectValue] = useState("");
  const [deletedSubject, setDeletedSubject] = useState({});
  const [editedSubject, setEditedSubject] = useState({});
  const [onChange, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const authHeader = useAuthHeader();
  const [isOpenAddModel, setIsOpenAddModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [isOpenEditModel, setIsOpenEditModel] = useState(false);

  const handleOpenAddModel = () => {
    Api.grades
      .getAllGrades(authHeader)
      .then((res) => {
        if (res.status) {
          setIsOpenAddModel(true);
          setGradesData(res.data);
          setSelectedItems([]);
        }
      })
      .catch((err) => {});
  };

  const handleCloseAddModel = () => {
    setIsOpenAddModel(false);
  };
  const handleOpenDeleteModel = () => {
    setIsOpenDeleteModel(true);
  };

  const handleCloseDeleteModel = () => {
    setIsOpenDeleteModel(false);
  };
  const handleOpenEditModel = (subject) => {
    Api.grades
      .getAllGrades(authHeader)
      .then((res) => {
        if (res.status) {
          setIsOpenEditModel(true);
          setSelectedItems(subject.grades?.map((item) => item.id) || []);
          setGradesData(res.data);
          setEditedSubject(subject);
          setEditSubjectValue(subject.s_name);
        }
      })
      .catch((err) => {});
  };

  const handleCloseEditModel = () => {
    setIsOpenEditModel(false);
  };

  useEffect(() => {
    document.title="QR system - Subjects"

    // console.log(authHeader)
    setLoading(true);
    Api.subjects
      .getAllSubjects(authHeader)
      .then((res) => {
        setSubjectsData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onChange]);

  const handleAddSubject = () => {
    setAddLoading(true);
    Api.subjects
      .addSubject(
        { subject_name: newSubjectValue, grades_ids: selectedItems },
        authHeader
      )
      .then((res) => {
        // console.log(res);
        if (res.status) {
          handleCloseAddModel();
          setNewSubjectValue("");
          setAddLoading(false);
          setSelectedItems([]);
          setChange(!onChange);
          toast.success("Added successfully");
        } else {
          toast.error("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        setAddLoading(false);
        toast.error("An error occurred. Please try again");
      });
  };
  const handleDeleteSubject = () => {
    setDeleteLoading(true);
    Api.subjects
      .softDeleteSubject({ subject_id: deletedSubject?.id }, authHeader)
      .then((res) => {
        // console.log(res);
        if (res.status) {
          handleCloseDeleteModel();
          setDeletedSubject({});
          setDeleteLoading(false);
          setChange(!onChange);
          toast.success("Deleted successfully");
        } else {
          toast.error("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        setDeleteLoading(false);
        toast.error("An error occurred. Please try again");
      });
  };
  const handleEditSubject = () => {
    setEditLoading(true);
    Api.subjects
      .updateSubject(
        {
          subject_id: editedSubject?.id,
          subject_name: editSubjectValue,
          grades_ids: selectedItems,
        },
        authHeader
      )
      .then((res) => {
        // console.log(res);
        if (res.status) {
          handleCloseEditModel();
          setEditedSubject({});
          setEditSubjectValue("");
          setEditLoading(false);
          setChange(!onChange);
          toast.success("Edited successfully");
        } else {
          toast.error("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        setEditLoading(false);
        toast.error("An error occurred. Please try again");
      });
  };
  if (loading) return <Loading />;
  return (
    <div className="w-100 flex flex-col gap-y-5">
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-between w-full">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            Subjects
          </p>
          <button
            onClick={handleOpenAddModel}
            className="border m-3 px-4 py-2 rounded bg-primary text-white"
          >
            Add New Subject
          </button>{" "}
        </div>
      </div>

      <div className="w-100 px-5">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden -6">
          <thead className="min-w-full bg-gray-200 text-gray-700 ">
            <tr className="min-w-full flex flex-row">
              <th className=" flex-none max-w-fit min-w-[80px]  px-4 py-2 text-left">
                #
              </th>
              <th className="grow px-4 py-2  text-left">Grade Name</th>
              <th className=" flex-none max-w-fit min-w-[100px] px-4 py-2 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="min-w-full text-gray-600">
            {subjectsData?.length === 0 ? (
              <tr className="min-w-full flex flex-row">
                <th></th>
                <th className="grow px-4   py-2">No data found</th>
                <th></th>
              </tr>
            ) : null}

            {subjectsData?.map((subject, index) => (
              <tr
                key={subject.id}
                className="min-w-full flex flex-row transition-all hover:bg-gray-100 border-b border-gray-200"
              >
                <td className="flex-none max-w-fit min-w-[80px] px-4   py-2">
                  {index + 1}
                </td>
                <td className="grow px-4   py-2">{subject.s_name}</td>
                <td className="flex-none max-w-fit min-w-[100px]">
                  <button
                    className=" hover:text-primary focus:outline-none rounded-full p-2"
                    onClick={() => {
                      handleOpenEditModel(subject);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700 focus:outline-none rounded-full p-2"
                    onClick={() => {
                      setDeletedSubject(subject);
                      handleOpenDeleteModel();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Model
        isOpen={isOpenAddModel}
        onClose={handleCloseAddModel}
        title="Add New Subject"
      >
        <div className="flex flex-col gap-4 justify-center">
          <input
            type="text"
            id="subjectName"
            name="subjectName"
            value={newSubjectValue}
            onChange={(e) => setNewSubjectValue(e.target.value)}
            className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            placeholder="Enter Subject Name"
          />
          <CheckboxList
            data={gradesData}
            selectedItems={selectedItems}
            setSelectedItems={(s) => {
              setSelectedItems(s);
            }}
          />
          <button
            onClick={handleAddSubject}
            className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
          >
            {addLoading ? (
              <Loading size="sm" color="text-white" />
            ) : (
              "Add New Subject"
            )}
          </button>
        </div>
      </Model>
      <Model
        isOpen={isOpenDeleteModel}
        onClose={handleCloseDeleteModel}
        title="Delete Subject"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-red-600">{deletedSubject.s_name}</h3>
          <button
            onClick={handleDeleteSubject}
            className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-red-700 hover:bg-opacity-95 text-white"
          >
            {deleteLoading ? (
              <Loading size="sm" color="text-white" />
            ) : (
              "Delete Subject"
            )}
          </button>
        </div>
      </Model>
      <Model
        isOpen={isOpenEditModel}
        onClose={handleCloseEditModel}
        title="Edit Subject"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-slate-700">{editedSubject.s_name}</h3>
          <div className="flex flex-col gap-4 justify-center">
            <input
              type="text"
              id="subjectName"
              name="subjectName"
              value={editSubjectValue}
              onChange={(e) => setEditSubjectValue(e.target.value)}
              className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              placeholder="Enter Subject Name"
            />
            <CheckboxList
              data={gradesData}
              selectedItems={selectedItems}
              setSelectedItems={(s) => {
                setSelectedItems(s);
              }}
            />
            <button
              onClick={handleEditSubject}
              className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
            >
              {editLoading ? (
                <Loading size="sm" color="text-white" />
              ) : (
                "Edit Subject"
              )}
            </button>
          </div>
        </div>
      </Model>
    </div>
  );
};
