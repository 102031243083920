import React, { useEffect, useState } from "react";
import Model from "../../components/model";
import Api from "../../api/api";
import Loading from "../../components/loading";
import ModelBtn from "../../components/modelBtn";
import Customize from "./Customize";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const EditModel = ({ itemName, itemId, Refresh }) => {
  console.log(itemName);
  console.log("itemId is :- ", itemId);
  const [Load, setLoad] = useState(false);
  const [isOpenDeleteModel, setisOpenDeleteModel] = useState(false);
  const [isOpenEditModel, setIsOpenEditModel] = useState(false);
  const [input, setInput] = useState("");
  const [Open, setOpen] = useState(false);
  const handelOpen = () => {
    console.log("handelOpen");
    setOpen(true);
  };
  const handelClose = () => {
    console.log("handelClose");
    setOpen(false);
  };
  const authHeader = useAuthHeader();

  const handelEditYear = () => {
    setLoad(true);
    const data = { term_id: itemId, term_name: input };
    Api[Customize.ApiName]
      .update(authHeader, data)
      .then((res) => {
        console.log(res);
        if (res.status) {
          Refresh();
        }
        setLoad(false);
        setIsOpenEditModel(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const handelRestoreYear = () => {
    console.log("handelRestoreYear");
  };

  return (
    <div className="relative  inline-block text-left flex flex-row gap-4 ">
      {/* Model  --------------------------------------------------------*/}

      <button
        onClick={handelOpen}
        className=" hover:text-primary focus:outline-none rounded-full p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
          />
        </svg>
      </button>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-50 flex justify-center items-center transition-opacity duration-300 ease-in-out ${
          Open
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="relative bg-white rounded-lg shadow-md p-4 overflow-auto max-h-[80vh] min-w-[350px]">
          <h3 className="mb-3 text-xl font-medium">Edit {Customize.name}</h3>

          <div className="flex justify-center items-center flex-col gap-3">
            <div className="w-full ">
              <h3 className="text-slate-700">{itemName}</h3>

              <input
                type="text"
                placeholder={`Enter ${Customize.name}`}
                className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
            <button
              onClick={handelEditYear}
              className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
            >
              {Load ? <Loading size="sm" color={"white"} /> : "Edit Term"}
            </button>
          </div>

          {true && (
            <button
              className="absolute top-0 right-0 mt-4 px-4 py-2 rounded-md text-sm font-medium"
              // onClick={onClose}
            >
              <svg
                onClick={handelClose}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditModel;
