 import { ApiHandler } from "./index";
import axios from "axios";

const api = new ApiHandler();

const pdfApiEndPoint = {
  generatePDF: async (data, token) => {
    try {
      const res = await api.post("/generate/pdf",data, token);
      return res;
    } catch (error) {
      console.error("Error generate pdf:", error);
    }
  },
};

export default pdfApiEndPoint;
