// import toast from "react-hot-toast";
import { ApiHandler } from "./index";
import axios from "axios";

const api = new ApiHandler();

const UsersApiEndPoint = {

  getlogapi: async (token, params) => {
    try {
      const res = await api.get("/getlogapi/info", token,params);
      console.log(res.data);
      return res;
    } catch (error) {
      // toast.error("This didn't work.");

      console.error("Error fetching /year/view:", error);
      // toast.error("This didn't work.");

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  // updateQr: async (token, data) => {
  //   try {
  //     const res = await api.post("/update", data, token);
  //     console.log(res.data);
  //     return res;
  //   } catch (error) {
      // toast.error("This didn't work.");

  //     console.error("Error fetching /year/view:", error);
      // toast.error("This didn't work.");

  //     // Handle errors appropriately (e.g., throw, return error object)
  //   }
  // },
};

export default UsersApiEndPoint;
