import React from "react";

export default function Test({ Os }) {
  return (
    <div className=" flex m-3 bg-white flex-col border w-full max-w-80 shadow rounded-xl
      my-custom-style
     ">
      <h1 className="text-3xl mx-3">OS</h1>
      
      <div className="flex flex-row gap-4 m-4 p-3   justify-between">
          <div className="text-2xl ">name</div>
          <div className="text-2xl ">24</div>
       </div>
       
    </div>
  );
}
