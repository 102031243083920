import { useEffect, useState } from "react";
import Api from "../../api/api";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Loading from "../../components/loading";
import Model from "../../components/model";
import toast from "react-hot-toast";

export const Teachers = (props) => {
  const [teachersData, setTeachersData] = useState([]);

  const [onChange, setChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();

  useEffect(() => {
    document.title = "QR system - teachers";

    // console.log(authHeader)
    setLoading(true);
    Api.teachers
      .getTeachers(authHeader)
      .then((res) => {
        setTeachersData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onChange]);

  if (loading) return <Loading />;
  return (
    <div className="w-100 flex flex-col gap-y-5">
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-between w-full">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            Teachers
          </p>
        </div>
      </div>

      <div className="w-100 px-5">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden -6">
          <thead className="min-w-full bg-gray-200 text-gray-700 ">
            <tr className="min-w-full ">
              <th className="  max-w-fit min-w-[80px]  px-4 py-2 text-left">
                #
              </th>
              <th className=" px-4 py-2  text-left"> Name</th>
              <th className=" px-4 py-2  text-left">Phone Number</th>
              <th className=" px-4 py-2  text-left">grade</th>
              <th className=" px-4 py-2  text-left">levels</th>
              <th className=" px-4 py-2  text-left">governorate</th>
              <th className=" px-4 py-2  text-left">directorate</th>
              <th className=" px-4 py-2  text-left">school</th>
              <th className=" px-4 py-2  text-left">school_type</th>
              <th className=" px-4 py-2  text-left">Date</th>
            </tr>
          </thead>
          <tbody className="min-w-full text-gray-600">
            {teachersData?.length === 0 ? (
              <tr className="min-w-full flex flex-row">
                <th></th>
                <th className=" px-4   py-2">No data found</th>
                <th></th>
              </tr>
            ) : null}

            {teachersData?.map((teacher, index) => (
              <tr
                key={teacher.id}
                className="min-w-full  transition-all hover:bg-gray-100 border-b border-gray-200"
              >
                <td className=" max-w-fit min-w-[80px] px-4   py-2">
                  {index + 1}
                </td>
                <td className=" px-4   py-2">{teacher.full_name}</td>
                <td className=" px-4   py-2">{teacher.phone}</td>
                <td className=" px-4   py-2">{teacher.g_name}</td>
                <td className=" px-4   py-2">
                  {Array.isArray(JSON.parse(teacher?.levels))&& JSON.parse(teacher?.levels)?.map((level) => (
                    <span className="inline-block px-2 py-1 bg-gray-200 rounded m-1">
                      {level}
                    </span>
                  ))}
                </td>
                <td className=" px-4   py-2">{teacher.governorate}</td>
                <td className=" px-4   py-2">{teacher.directorate}</td>
                <td className=" px-4   py-2">{teacher.school}</td>
                <td className=" px-4   py-2">{teacher.school_type}</td>
                <td className=" px-4   py-2">
                  {new Date(teacher.created_at)?.toDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
