import axios from "axios";
import { ApiHandler } from "./index";
import toast from "react-hot-toast";

const api = new ApiHandler();

const termsApiEndPoint = {
  getAll: async (token) => {
    try {
      const res = await api.get("/term/view", token);
      // toast.success("Successfully all.");

      return res;
    } catch (error) {
      // toast.error("This didn't work.");

      console.error("Error fetching /term/view:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  archived: async (token) => {
    try {
      const res = await api.get("/term/viewarchived", token);

      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /term/viewarchived:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  softDelete: async (token, data) => {
    console.log(data);
    try {
      const res = await api.post("/term/soft_delete", data, token);
      toast.success("Deleted successfully");

      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /term/soft_delete:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  hardDelete: async (token, data) => {
    console.log(data);
    try {
      const res = await api.post("/term/hard_delete", data, token);
      toast.success("Deleted successfully");

      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /term/hard_delete:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  restore: async (token, data) => {
    console.log(data);
    try {
      const res = await api.post("/term/restore_data", data, token);
      toast.success("Restored successfully");

      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /term/restore_data:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  update: async (token, data) => {
    console.log(data);
    try {
      const res = await api.post("/term/update", data, token);
      toast.success("Edited successfully");

      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /term/update:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  Create: async (token, data) => {
    console.log(data);
    try {
      const res = await api.post("/term/add", data, token);
      toast.success("Added successfully");

      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /term/add:", error);
    }
  },
};

export default termsApiEndPoint;
