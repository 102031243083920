export const DeviceTablet = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4.5835"
          y="2.75"
          width="12.8333"
          height="16.5"
          rx="1"
          stroke="#2F2B3D"
          stroke-opacity="0.9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <ellipse
          cx="11.0002"
          cy="15.5833"
          rx="0.916667"
          ry="0.916667"
          stroke="#2F2B3D"
          stroke-opacity="0.9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
