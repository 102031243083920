import { Link, useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import Api from "../../api/api";
import toast from "react-hot-toast";
import Model from "../../components/model";
import Select from "react-select";

export const CreateQR = (props) => {
  const navigate = useNavigate();
  const useHeader = useAuthHeader();
  const [loading, setLoading] = useState(false);
  const [addingLoading, setAddingLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [gradesData, setGradesData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [qrData, setQrData] = useState({});

  const [formData, setFormData] = useState({
    grade_id: [],
    subject_id: "",
    year: "",
    term_id: "",
    link: "",
    tree: "",
    title: "",
    type: "",
    file: null,
    is_external: 0,
    can_download: 0,
    is_list: 0,
    video_id: "",
  });
  const handleOpenModel = () => {
    setIsOpenModel(true);
  };

  const handleCloseModel = () => {
    setFormData({
      grade_id: [],
      subject_id: "",
      year: "",
      term_id: "",
      link: "",
      tree: "",
      title: "",
      type: "",
      file: null,
      is_external: 0,
      can_download: 0,
      is_list: 0,
      video_id: "",
    });
    setIsOpenModel(false);
  };
  const handleChange = (event) => {
    if (event.target.name === "grade_id" && event.target.value) {
      Api.subjects
        .getSubjectByGradeId(event.target.value, useHeader)
        .then((res) => {
          if (res.status) {
            setSubjectsData(res?.data?.subjects);
          }
        })
        .catch((err) => {});
    }
    if (event.target.name === "grade_id" && event.target.value === "") {
      setSubjectsData([]);
    }
    if (event.target.name === "file") {
      // console.log(event.target.files[0])
      setFormData({
        ...formData,
        file: event.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleChangeGrades = (grades) => {
    setFormData({
      ...formData,
      grade_id: grades,
    });
    Api.subjects
      .getSubjectByGradeId(useHeader, {
        grade_ids: grades?.map((g) => {
          return g.id;
        }),
      })
      .then((res) => {
        if (res.status) {
          setSubjectsData(res?.data);
        }
      })
      .catch((err) => {});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAddingLoading(true);
    const newFormData={...formData,grade_id:`[${formData?.grade_id?.map((g)=>{return g.id})}]`}
    const formDataToSend = new FormData();
    for (const key in newFormData) {
      console.log(key,newFormData[key])
      formDataToSend.append(key, newFormData[key]);
    }
    console.log(newFormData)

    Api.qr
      .createQr(formDataToSend, useHeader)
      .then((res) => {
        if (res.status) {
          toast.success("Added successfully");
          setQrData(res.data);
          handleOpenModel();
          setAddingLoading(false);
        } else {
          toast.error("An error occurred. Please try again");
          setAddingLoading(false);
        }
      })
      .catch((err) => {
        setAddingLoading(false);

        toast.error("An error occurred. Please try again");
      });
  };

  useEffect(() => {
    document.title = "QR system - Create New QR";

    setLoading(true);
    Api.grades
      .getAllGrades(useHeader)
      .then((res1) => {
        if (res1.status) {
          setGradesData(res1.data);
        }
        return Api.Terms.getAll(useHeader);
      })
      .then((res3) => {
        if (res3.status) {
          setTermsData(res3.data);
        }
        return Api.Years.getAll(useHeader);
      })
      .then((res4) => {
        if (res4.status) {
          setYearsData(res4.data);
        }
        return Api.Type.getAll(useHeader);
      })
      .then((res5) => {
        if (res5.status) {
          setTypeData(res5.data);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  // console.log(formData);
  const handleDownloadZip = async () => {
    // Open the ZIP download page in a new tab
    window.open(qrData["zip_file_path"], "_blank");
  };
  if (loading) return <Loading />;

  return (
    <div className="w-100 flex flex-col gap-5">
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            Create New QR
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="w-100 p-2">
        <div className="w-100 grid grid-cols-1 sm:grid-cols-2 pt-10 gap-10 bg-white p-2 rounded-lg">
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="grade_id" className=" text-sm font-medium">
              Grade: <span className="text-red-600">*</span>
            </label>
            {/* <select
              id="grade_id"
              name="grade_id"
              value={formData.grade_id}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              size={1}
              multiple
            >
              <option value="">-- Select Grade --</option>
              {gradesData.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.g_name}
                </option>
              ))}
            </select> */}
            <Select
              isMulti // Enable multi-select
              id="grade_id"
              name="grade_id" // Name for the generated array
              defaultValue={formData.grade_id} // Initial selection
              value={formData.grade_id} // Controlled value
              className="flex-grow"
              placeholder="-- Select Grade --"
              onChange={
                handleChangeGrades
                //   (newValue) => {
                //   setFormData({
                //     ...formData,
                //     grade_id: newValue,
                //   });
                //   // Update the multi-select value
                //   // setValue("SubjectDivisions", newValue);
                // }
              }
              options={gradesData}
              isSearchable
              isClearable
              getOptionLabel={(option) => option.g_name}
              getOptionValue={(option) => option.id}
            />
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="subject_id" className=" text-sm font-medium">
              Subject: <span className="text-red-600">*</span>
            </label>
            <select
              id="subject_id"
              name="subject_id"
              value={formData.subject_id}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Subject --</option>
              {subjectsData.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.s_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="term_id" className=" text-sm font-medium">
              Term: <span className="text-red-600">*</span>
            </label>
            <select
              id="term_id"
              name="term_id"
              value={formData.term_id}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Term --</option>
              {termsData.map((term) => (
                <option key={term.id} value={term.id}>
                  {term.t_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="year" className=" text-sm font-medium">
              Year: <span className="text-red-600">*</span>
            </label>
            <select
              id="year"
              name="year"
              value={formData.year}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Year --</option>
              {yearsData.map((year) => (
                <option key={year.id} value={year.y_name}>
                  {year.y_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="type" className=" text-sm font-medium">
              Type: <span className="text-red-600">*</span>
            </label>
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select type --</option>
              {typeData.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="is_external" className=" text-sm font-medium">
              Internal or External: <span className="text-red-600">*</span>
            </label>
            <div className="h-full flex items-start space-x-4">
              <label>
                <input
                  type="radio"
                  name="is_external"
                  value={0}
                  checked={formData.is_external == 0}
                  onChange={handleChange}
                  className="mr-2"
                />
                Internal
              </label>
              <label>
                <input
                  type="radio"
                  name="is_external"
                  value={1}
                  checked={formData.is_external == 1}
                  onChange={handleChange}
                  className="mr-2"
                />
                External
              </label>
            </div>
          </div>
          {formData.type == "1" && (
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="link" className=" text-sm font-medium">
                Link: <span className="text-red-600">*</span>
              </label>
              <input
                id="link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                readOnly={formData.type !== "1"}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
                type="text" // Set input type to "text" for a text field
              />
            </div>
          )}
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="tree" className=" text-sm font-medium">
              Note: <span className="text-red-600">*</span>
            </label>
            <input
              id="tree"
              name="tree"
              value={formData.tree}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              type="text" // Set input type to "text" for a text field
            />
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="title" className=" text-sm font-medium">
              Title: <span className="text-red-600">*</span>
            </label>
            <input
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              type="text" // Set input type to "text" for a text field
            />
          </div>
          {(formData.type == "3" || formData.type == "4") && (
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="can_download" className=" text-sm font-medium">
                can download: <span className="text-red-600">*</span>
              </label>
              <div className="h-full flex items-start space-x-4">
                <label>
                  <input
                    type="radio"
                    name="can_download"
                    value={1}
                    checked={formData.can_download == 1}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="can_download"
                    value={0}
                    checked={formData.can_download == 0}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  no
                </label>
              </div>
            </div>
          )}
          {formData.type == "5" && (
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="is_list" className=" text-sm font-medium">
                youtube type: <span className="text-red-600">*</span>
              </label>
              <div className="h-full flex items-start space-x-4">
                <label>
                  <input
                    type="radio"
                    name="is_list"
                    value={1}
                    checked={formData.is_list == 1}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  PlayList
                </label>
                <label>
                  <input
                    type="radio"
                    name="is_list"
                    value={0}
                    checked={formData.is_list == 0}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  video
                </label>
              </div>
            </div>
          )}
          {formData.type == "5" && (
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="video_id" className=" text-sm font-medium">
                Video Id: <span className="text-red-600">*</span>
              </label>
              <input
                id="video_id"
                name="video_id"
                value={formData.video_id}
                onChange={handleChange}
                readOnly={formData.type !== "5"}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
                type="text" // Set input type to "text" for a text field
              />
            </div>
          )}
          {(formData.type == "2" ||
            formData.type == "3" ||
            formData.type == "4") && (
            <div className="col-span-2 sm:col-span-1 flex flex-row flex-wrap gap-4">
              <label htmlFor="file" className=" text-sm font-medium">
                {formData.type == "2" && "Update HTML Folder (zip File):"}
                {formData.type == "3" && "Update video file :"}
                {formData.type == "4" && "Update pdf file:"}

                <span className="text-red-600">*</span>
              </label>
              <input
                id="file"
                name="file"
                onChange={handleChange}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
                type="file"
                accept={`${
                  formData.type == "2"
                    ? ".zip"
                    : formData.type == "3"
                    ? "video/*"
                    : formData.type == "4"
                    ? ".doc,.docx,.pdf,.txt,.ppt,.pptx"
                    : ""
                }`}
                multiple={false}
              />
              {formData.type === "2" && (
                <p className="text-xs text-red-500">
                  **Please upload a zip file containing an `index.html` file.**
                  This file is essential for correct rendering of the HTML
                  content.
                </p>
              )}
            </div>
          )}
          <div className="w-100 text-center py-4 col-span-2">
            <button
              type="submit"
              className="w-fit mx-auto py-2 px-6 bg-primary text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2"
            >
              {addingLoading ? (
                <Loading size="sm" color="text-white" />
              ) : (
                "Create New QR"
              )}
            </button>
          </div>
        </div>

        {/* Add additional input fields as needed */}
      </form>
      <Model
        isOpen={isOpenModel}
        onClose={handleCloseModel}
        title="Download & view (QR)"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-slate-700">Qr created successfully</h3>
          <div className="flex flex-col gap-4 justify-center">
            <div className="flex flex-row justify-center">
              <div className="qr-container max-w-[250px]">
                <img src={qrData?.qrSVG} alt="qr code" />
              </div>
            </div>
            <div className="flex flex-row">
              <button
                onClick={handleDownloadZip}
                className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
              >
                {downloadLoading ? (
                  <Loading size="sm" color="text-white" />
                ) : (
                  "Download ZIP"
                )}
              </button>
            </div>
          </div>
        </div>
      </Model>
    </div>
  );
};
