import React from "react";
import { useState } from "react";

export default function Pagination({ current_page, last_page,per_page ,callback,set_per_page }) {
  const [value, setValue] = useState(per_page);

 

  const sendDataToParent = (i) => {
    console.log(i);
    callback(i);
  };
  const Send_per_page = (i) => {
    console.log(i);
    set_per_page(i);
  };
  const renderDivs = () => {
    const divs = [];
    for (let i = 1; i <= last_page; i++) {
      divs.push(
        <span
        onClick={()=>{
          sendDataToParent(i)
        }}
          className={`  px-4 py-2  text-gray-700 border rounded-md border-gray-300   hover:bg-gray-100 hover:text-black  cursor-pointer ${i==current_page ? "bg-blue-500 text-white" : "bg-white"}`}
        >
          {i}
        </span>
      );
    }
    return divs;
  };

  return (
    <>
      <div className="m-3 h-11 flex flex-row gap-3 justify-center">
        <div className={`  grow flex flex-row gap-3 overflow-x-auto h-fit`}>
           {renderDivs()}
        </div>

        <span>

        <button onClick={() => Send_per_page(value)} className="px-4 py-2 text-gray-700 bg-white border rounded-md border-gray-300 ">GO</button>
        <input
          
          className="px-4 w-20 py-2 text-gray-700 bg-white border rounded-md border-gray-300 "
          onChange={(e) => setValue(e.target.value)}
          value={value}
          type="number"
          step={10}
          max={100}
          min={10}
        />
      </span>
      </div>
    </>
  );
}
