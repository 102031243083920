import React, { useEffect, useState } from "react";
import ModelBtn from "../../components/modelBtn";
import Api from "../../api/api";
import Loading from "../../components/loading";
import Customize from "./Customize";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

export default function Addscomponent({ setRefresh }) {
  const [Load, setLoad] = useState(false);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState();

  const authHeader = useAuthHeader();

  const handelAdd = () => {
    setLoad(true);

    console.log(input);
    const APINAME = Customize.ApiName;
    const data = { term_name: input };
    Api[Customize.ApiName]
      .Create(authHeader, data)
      .then((res) => {
        console.log(res);
        if (res.status) {
          setRefresh();
          setIsOpen(false);
        }
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  return (
    <div>
      <ModelBtn
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(true);
        }}
        title="Add New Term"
        btn={
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className="border m-3 px-4 py-2 rounded bg-primary text-white"
          >
            {Customize.addBtn}{" "}
          </button>
        }
      >
        <div className="flex justify-center items-center flex-col gap-3">
          <div className="w-full ">
            <input
              type="text"
              className="w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              placeholder="Enter Term Name"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <button
            onClick={handelAdd}
            className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
          >
            {Load ? <Loading size="sm" color={"white"} /> : "Add Term"}
          </button>
        </div>
      </ModelBtn>
    </div>
  );
}
