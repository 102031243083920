 import { ApiHandler } from "./index";
// import toast from "react-hot-toast";

const api = new ApiHandler();

const TypeApiEndPoint = {
  getAll: async (token) => {
    try {
      const res = await api.get(
        "/typelink/getall",
        token
      );
      // toast.success("Successfully all.");

      return res;
    } catch (error) {
      // toast.error("This didn't work.");

      console.error("Error fetching /year/view:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  // archived: async (token) => {
  //   try {
  //     const res = await api.get(
  //       "/year/viewarchived",
  //       token
  //     );
  //     toast.success("Successfully .");

  //     return res;
  //   } catch (error) {
  //     toast.error("This didn't work.");

  //     console.error("Error fetching /year/viewarchived:", error);

  //     // Handle errors appropriately (e.g., throw, return error object)
  //   }
  // },
  // softDelete: async (token, data) => {
  //   console.log(data);
  //   try {
  //     const res = await api.post(
  //       "/year/soft_delete",
  //       data,
  //       token
  //     );
  //     toast.success("Successfully .");

  //     return res;
  //   } catch (error) {
  //     toast.error("This didn't work.");

  //     console.error("Error fetching /year/soft_delete:", error);

  //     // Handle errors appropriately (e.g., throw, return error object)
  //   }
  // },
  // hardDelete: async (token, data) => {
  //   console.log(data);
  //   try {
  //     const res = await api.post(
  //       "/year/hard_delete",
  //       data,
  //       token
  //     );
  //     toast.success("Successfully .");

  //     return res;
  //   } catch (error) {
  //     toast.error("This didn't work.");

  //     console.error("Error fetching /year/hard_delete:", error);

  //     // Handle errors appropriately (e.g., throw, return error object)
  //   }
  // },
  // restore: async (token, data) => {
  //   console.log(data);
  //   try {
  //     const res = await api.post(
  //       "/year/restore_data",
  //       data,
  //       token
  //     );
  //     toast.success("Successfully .");

  //     return res;
  //   } catch (error) {
  //     toast.error("This didn't work.");

  //     console.error("Error fetching /year/restore_data:", error);

  //     // Handle errors appropriately (e.g., throw, return error object)
  //   }
  // },
  // update: async (token, data) => {
  //   console.log(data);
  //   try {
  //     const res = await api.post(
  //       "/year/update",
  //       data,
  //       token
  //     );
  //     toast.success("update  Successfully .");

  //     return res;
  //   } catch (error) {
  //     toast.error("This didn't work.");

  //     console.error("Error fetching /year/update:", error);

  //     // Handle errors appropriately (e.g., throw, return error object)
  //   }
  // },
  // Create: async (token, data) => {
    // console.log(data);
    // try {
    //   const res = await api.post(
    //     "/year/add",
    //     data,
    //     token
    //   );
    //   toast.success("update  Successfully .");

    //   return res;
    // } catch (error) {
    //   toast.error("This didn't work.");

    //   console.error("Error fetching /year/add:", error);

    //   // Handle errors appropriately (e.g., throw, return error object)
    // }
  // },
};

export default TypeApiEndPoint;
