import toast from "react-hot-toast";
import { ApiHandler } from "./index";
import axios from "axios";

const api = new ApiHandler();

const ZipApiEndPoint = {
  generateZip: async (data, token) => {
    try {
      const res = await api.post("/generate/zipfile",data, token);
      return res;
    } catch (error) {
      console.error("Error generate pdf:", error);
    }
  },
};

export default ZipApiEndPoint;
