import { ApiHandler } from './index'

const api = new ApiHandler()

const subjectsApiEndPoint = {
  getAllSubjects: async (token) => {
    try {
      const res = await api.get('/subject/view',token)
      return res
    } catch (error) {
      console.error('Error fetching subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  getSubjectByGradeId: async (token,gradeIds) => {
    try {
      const res = await api.post(`/subject/getbygrade`,gradeIds,token)
      console.log(res)
      return res
    } catch (error) {
      console.error('Error fetching subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  getAllArchivedSubjects: async (token) => {
    try {
      const res = await api.get('/subject/viewarchived',token)
      return res
    } catch (error) {
      console.error('Error fetching subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  addSubject: async (data,token) => {
    try {
      const res = await api.post('/subject/add',data,token)
      return res
    } catch (error) {
      console.error('Error adding subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  updateSubject: async (data,token) => {
    try {
      const res = await api.post('/subject/update',data,token)
      return res
    } catch (error) {
      console.error('Error Updating subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  restoreSubject: async (data,token) => {
    try {
      const res = await api.post('/subject/restore_data',data,token)
      return res
    } catch (error) {
      console.error('Error Updating subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  softDeleteSubject: async (data,token) => {
    try {
      const res = await api.post('/subject/soft_delete',data,token)
      return res
    } catch (error) {
      console.error('Error fetching subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  hardDeleteSubject: async (data,token) => {
    try {
      const res = await api.post('/subject/hard_delete',data,token)
      return res
    } catch (error) {
      console.error('Error fetching subjects:', error)

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
}

export default subjectsApiEndPoint
