import React, { useEffect, useRef, useState } from "react";
import Api from "../api/api";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { Link, useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

export default function NavBar({ handleToggleSideBar }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const authHeader = useAuthHeader();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
const useHeader=useAuthHeader();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    Api.auth
      .getProfile(useHeader)
      .then((res) => {
        if (res.status) {
            setUser({
            name: res.data.name,
            email: res.data.email,
          });
        }
      })
      .catch((err) => {
      });
  }, [document.cookie]);
  const handleOpenDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };
  const handleLogOut = () => {
    Api.auth
      .logout(authHeader)
      .then((res) => {
        console.log(res);
        if (res.status) {
          signOut();
          navigate("/login");
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="w-100 py-2 px-3  rounded-xl mb-2 flex flex-row justify-between bg-white">
      <button
        className="p-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200"
        onClick={handleToggleSideBar}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
          />
        </svg>
      </button>
      <div className="relative" ref={dropdownRef}>
        <button
          className="p-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200"
          onClick={handleOpenDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
            />
          </svg>
        </button>
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-64 flex flex-col gap-y-2 bg-white border rounded-md shadow-lg z-10 p-3">
            {/* Dropdown content */}
            <div className=" border-b pb-2">
              <p className="text-lg font-medium p-0 m-0"> {user?.name}</p>
              <p className="text-gray-500 p-0 m-0"> {user?.email}</p>
            </div>
            <Link
              className=" flex flex-row gap-x-3 font-medium text-gray-700 p-2 rounded-md hover:bg-gray-200 focus:outline-none "
              to="/update-profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                />
              </svg>
              Update Profile
            </Link>
            <button
              className="p-2 flex flex-row gap-3 justify-center rounded-md bg-red-600 hover:bg-red-500 text-white focus:outline-none focus:ring-2 focus:ring-gray-200"
              onClick={handleLogOut}
            >
              Logout
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#FFFFFF"
                >
                  <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
                </svg>
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
