import React, { useEffect, useState } from "react";
import Api from "../../api/api.js";
import Customize from "./Customize.js";
import Loading from "../../components/loading.js";
import HardDeleteModel from "./HardDeleteModel.js";
import Addscomponent from "./Addscomponent.js";
import EditModel from "./RestoreModel.js";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Link } from "react-router-dom";
import Popover from "../Terms/Popover .js";
import Pagination from "./Pagination.js";

export const Users = (props) => {
  const [Load, setLoad] = useState(false);
  const [Data, setData] = useState(false);
  const [number, setNumber] = useState(3);
  const [meta, setMeta] = useState(3);
  const [pageNumber, setpageNumber] = useState(1);
  const [Per_page, setPer_page] = useState(10);
  const authHeader = useAuthHeader();

  const handleCallback = (data) => {
    setpageNumber(data);
  };
  const handlePer_page = (data) => {
    setPer_page(data);
  };
  const fetchingData = () => {
    console.log("fetchingData");
    const data = { page: pageNumber, per_page: Per_page };
    setLoad(true);

    const x = {
      headers: {
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTkyLjE2OC4xMDYuMTgxOjgwMDAvbG9naW4iLCJpYXQiOjE3MTU1MjIxMDIsImV4cCI6MTcxNTUyNTcwMiwibmJmIjoxNzE1NTIyMTAyLCJqdGkiOiJGWE1DWExYRTZqT1pKNDBLIiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.KSzPeVM1iLFW8J27TPhD_pV4tZgGrSXId7jg_de8ECc",
      },
    };
    console.log();

    Api.users
      .getlogapi(authHeader, data)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setMeta(res.meta);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  useEffect(() => {
    document.title="QR system - Users"

    fetchingData();
  }, [pageNumber, Per_page]);

  return (
    <div>
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            {Customize.name}
          </p>
        </div>
      </div>

      <div className="overflow-x-auto m-auto px-5 ">
        <div className="flex   justify-cent      flex-col ">
          {/* <h1 className=" text-3xl p-5 m-auto">add limit</h1> */}
          {/* <div className="flex flex-row gap-1 ">
            <input
              className="w-52 h-10 border  ml-10  mb-4 border-gray-300 rounded "
              value={number}
              type="number"
              onChange={(e) => setNumber(e.target.value)}
            />
            <button
              className="bg-primary h-10 text-white p-1 rounded"
              onClick={fetchingData}
            >
              research
            </button>
          </div> */}
        </div>

        <div>{/* <Addscomponent /> */}</div>
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden -6">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="px-4 py-2 text-left">#</th>
              <th className="px-4 py-2 text-left">Ip</th>
              <th className="px-4 py-2 text-left">Device</th>
              <th className="px-4 py-2 text-left">Os</th>
              <th className="px-4 py-2 text-left">Browser</th>
              <th className="px-4 py-2 text-left">Link</th>
              {/* <th className="px-4 py-2 text-left">tree</th> */}
              <th className="px-4 py-2 text-left">Come From</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {Data?.length === 0 ? (
              <tr>
                <th></th>
                <th>No data  found</th>
                <th></th>
              </tr>
            ) : null}
            {Load ? (
              <tr>
                <th></th>
                <th>
                  <Loading />
                </th>
                <th></th>
              </tr>
            ) : (
              <>
                {Data &&
                  Data?.map((item, index) => (
                    <tr
                      key={item.id}
                      className="transition-all hover:bg-gray-100 border-b border-gray-200"
                    >
                      {console.log(item)}
                      <td className="px-4 py-2">{index + 1}</td>
                      <td className="px-4 py-2">{item?.ip_address} </td>
                      <td className="px-4 py-2">{item.device_type}</td>
                      <td className="px-4 py-2 text-left ">{item.os}</td>
                      <td className="px-4 py-2 text-left ">{item.browser}</td>
                      <td className="px-4 py-2 text-left ">
                        <a href={item.endpoint} target="_blank" className="text-blue-700 underline">{item.endpoint}</a>
                        </td>
                      {/* <td className="px-4 py-2 text-left ">{item.tree}</td> */}
                      <td className="px-4 py-2 text-left ">{item.referer}</td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>

        <Pagination
          callback={handleCallback}
          set_per_page={handlePer_page}
          per_page={meta?.per_page}
          current_page={meta?.current_page}
          total={meta?.total}
          last_page={meta?.last_page}
        />
      </div>
    </div>
  );
};
